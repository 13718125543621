.App {
  text-align: center;
}

.action-col {
  padding-right: 10px;
}

.success {
  color: #52c41a;
}

.error {
  color: #ff4d4f;
}

.warning {
  color: #faad14;
}
