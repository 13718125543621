.page-title {
  font-size: large;
  font-weight: bold;
}
.page-actions {
  float: right;
}
.page-content {
  margin-top: 10px;
}
