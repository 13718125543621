.logo {
  height: 32px;
  margin: 16px;
}

.site-layout-sub-header-background {
  text-align: left;
  padding: 0px 24px 0 24px;
  background: #fff;
}

.site-layout-background {
  padding: 24px;
  min-height: 360px;
  text-align: left;
  background: #fff;
}

.layout-wrapper {
  min-height: 100vh;
}

.site-layout-content {
  margin: 24px 16px 0;
}
.site-layout-footer {
  text-align: center;
}
