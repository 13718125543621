/**
* AntD style overwrites
*/

a {
  color: #0574a3;
}

.ant-layout {
  background: #e9edef !important;
}
.ant-layout-sider-children {
  width: 200px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
}
.ant-menu.ant-menu-dark {
  text-align: left;
}
.ant-menu-item-selected {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #0574a3 !important;
}

.ant-btn-primary {
  background: #0574a3;
  border-color: #0574a3;
}

.ant-switch-checked {
  background-color: #0574a3 !important;
}
